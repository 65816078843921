import React from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';
import { Map, Marker, Popup, TileLayer, Polyline } from 'react-leaflet';
import { TruckSelect, selectTruck, showNotice, showConfirm, MapTiles } from 'components';
import { fetch, paddedNumber, stateValueParser, toETRSTM35FIN } from '../utils.js';


export const RoadwaySelect = props => (
  <div>
    <label>Ajorata</label>
    { props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null }
    <label className='radio-container'>
      0
      <input type='radio' name='roadway' value='0'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 0}/>
      <span className="checkmark"></span>
    </label>
    <label className='radio-container'>
      1
      <input type='radio' name='roadway' value='1'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 1}/>
      <span className="checkmark"></span>
    </label>
    <label className='radio-container'>
      2
      <input type='radio' name='roadway' value='2'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 2}/>
      <span className="checkmark"></span>
    </label>
  </div>
);

export const DirectionSelect = props => (
  <div>
    <label>Suunta</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio-container'>
      1
      <input id='direction1' type='radio' name='direction' value='1'
        onClick={props.onChange.bind(this, 'direction', 'integer', null)}
        defaultChecked={props.direction === 1}/>
      <span className="checkmark"></span>
    </label>
    <label className='radio-container'>
      2
      <input id='direction2' type='radio' name='direction' value='2'
        onClick={props.onChange.bind(this, 'direction', 'integer', null)}
        defaultChecked={props.direction === 2}/>
      <span className="checkmark"></span>
    </label>
  </div>
);

export const LaneSelect = props => (
  <div>
    <label>Kaista</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio-container'>
      1
      <input type='radio' name='line' value='1'
        onClick={props.onChange.bind(this, 'lane', 'integer', null)} 
        defaultChecked={props.lane === 1}/>
      <span className="checkmark"></span>
    </label>
    <label className='radio-container'>
      2
      <input type='radio' name='line' value='2'
        onClick={props.onChange.bind(this, 'lane', 'integer', null)} 
        defaultChecked={props.lane === 2}/>
      <span className="checkmark"></span>
    </label>
  </div>
);

export const AccuracyFixer = props => {
  if (!props.show) return null;
  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='accuracy-modal'>
        <h4>Korjataan sijaintia epätarkkuuden takia...</h4>
        <h5>Tämän hetkinen tarkkuus: {props.accuracy} m</h5>
        <div className='loader'/>
        <br/>
        <button onClick={props.submit}>
          Hyväksy silti
        </button>
        <button onClick={props.toggle}>
          Syötä manuaalisesti
        </button>
      </div>
    </div>
  );
}

class ChangeMacadamView extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      height: 0,
      multiplier: 0,
      truckMass: null,
      attentions: null,
      confirmedRoad: false
    };

    this.changeState = this.changeState.bind(this);
    this.doChangeMacadam = this.doChangeMacadam.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.macadam == null) return;

    if (nextProps.selectedTruck != null && this.props.selectedTruck !== nextProps.selectedTruck) {
      this.setState({
        truckRegisterNumber: nextProps.selectedTruck.get('register_number'),
      });
    }

    if (nextProps.macadam === this.props.macadam) return;

    this.props.selectTruck(nextProps.macadam.get('truck'));

    this.setState({
      height: nextProps.macadam.get('height'),
      multiplier: nextProps.macadam.get('multiplier'),
      truckMass: nextProps.macadam.get('truck_mass'),
      attentions: nextProps.macadam.get('attentions')
    });
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }
    
    this.setState({[propertyName]: value});
  }

  async doChangeMacadam() {
    const height = parseFloat(this.state.height);
    if (height === 0) {
      this.props.showNotice('Korkeus ei voi olla 0', 'Warning')
      return;
    }

    const multiplier = parseFloat(this.state.multiplier);
    if (multiplier === 0) {
      this.props.showNotice('Kerroin ei voi olla 0', 'Warning')
      return;
    }

    if (this.props.selectedTruck == null) {
      this.props.showNotice('Rekkaa ei ole valittu', 'Warning');
      return;
    }

    const truckMass = parseFloat(this.state.truckMass);
    if (truckMass === 0) {
      this.props.showNotice('Kuorman massaa ei ole annettu', 'Warning');
      return;
    }

    let macadam = this.props.macadam;
    macadam = macadam.set('height', height);
    macadam = macadam.set('multiplier', multiplier);
    macadam = macadam.set('truckId', this.props.selectedTruck.get('id'));
    macadam = macadam.set('truck', null);
    macadam = macadam.set('truck_mass', truckMass);
    macadam = macadam.set('attentions', this.state.attentions);

    if (macadam.get('not_saved')) {
      this.props.changeLocalMacadam(macadam);
    }
    else {
      this.props.changeMacadam(macadam);
    }
  }

  render() {
    if (this.props.macadam == null) return null;

    return (
      <div onClick={this.props.clear} className='modal'>
        <div onClick={e => e.stopPropagation()} id='load-data-modal'>
          <h3 className='center'>Murskeen muokkaus</h3>
          <div className='row'>
            <div className="column">
              <label htmlFor='height'>Korkeus</label>
              <input id='height' type='tel'
                    onChange={this.changeState.bind(this, 'height', 'float', 0.0)} value={this.state.height} required/>
            </div>
            <div className="column">
              <label htmlFor='multiplier'>Kerroin</label>
              <input id='multiplier' type='tel'
                    onChange={this.changeState.bind(this, 'multiplier', 'float', 0.0)} value={this.state.multiplier} required/>
            </div>
          </div>
          <div className='row'>
            <div className='column'>
              <label htmlFor='truck'>Rekka</label>
                <TruckSelect required/>
            </div>
            <div className='column'>
              <label htmlFor='mass'>Kuorma (Tonnit) </label>
                <input id='mass' type='tel'
                        value={this.state.truckMass}
                        onChange={this.changeState.bind(this, 'truckMass', 'float', 0.0)} required/>
            </div>
            <div className='column'>
              <label htmlFor='attentions'>Huomiot</label>
              <input id='attentions' type='text' value={this.state.attentions || ''}
                    onChange={this.changeState.bind(this, 'attentions', 'string', '')}/>
            </div>
          </div>
          <div className='row'>
            <div className='column'>
              <button onClick={this.doChangeMacadam}>
                Muokkaa
              </button>
            </div>
            <div className='column'>
              <button className='button-outline' onClick={this.props.clear}>
                Peruuta
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  selectedTruck: state.truckSelect.get('selectedTruck'),
  trucks: state.truckSelect.get('trucks'),
}), { selectTruck, showNotice, showConfirm })(ChangeMacadamView);


export class MapView extends React.Component {

  constructor(props){
    super(props);
    this.update = this.update.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.getTrucks = this.getTrucks.bind(this);
    this.selectLine = this.selectLine.bind(this);

    this.trucks = [];
    this.position = [64.1, 26.5];
    this.zoom = 6;
    this.truckUpdateInterval = null;

    const height = window.innerHeight;

    this.state = {
      showedWarning: false,
      directions: [1, 2],
      trucks: [],
      lines: [],
      height: height,
      mapTilesUrl: '',
      mapTilesAttribution: '',
      maxZoom: 16
    };
  }

  componentDidMount() {
    if (this.props.mapPosition) {
      this.position = this.props.mapPosition;
      this.zoom = this.props.mapZoom;
    }

    this.update(this.props, this.state);
    this.getTrucks();
    this.truckUpdateInterval = setInterval(this.getTrucks, 5000);
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    clearInterval(this.truckUpdateInterval);
  }

  componentDidUpdate(prevProps, prevState) {
    const mapCenter = this.map.leafletElement.getCenter();
    const converted = toETRSTM35FIN(mapCenter.lat, mapCenter.lng)
    const mapTiles = MapTiles(converted.x, converted.y);

    if (this.state.mapTilesUrl !== mapTiles.url) {
      this.setState({
        mapTilesUrl: mapTiles.url,
        mapTilesAttribution: mapTiles.attribution,
        maxZoom: mapTiles.maxZoom
      });
    }

    if (this.props.mapPosition !== prevProps.mapPosition) {
      this.position = this.props.mapPosition;
      this.zoom = this.props.mapZoom;
    }
    if (this.state.directions !== prevState.directions ||
        this.state.selectedLine !== prevState.selectedLine ||
        this.props.mapPaths !== prevProps.mapPaths) {
      this.update(this.props, this.state);
    }
  }

  updateDimensions() {
    const height = window.innerHeight;
    this.setState({ height: height })
  }

  async update(props, state) {
    if (props.mapPaths.length === 0) {
      this.setState({ lines: [] })
      return;
    }

    let lines = [];

    for (let i = 0; i < props.macadams.size; i++) {
      const macadam = props.macadams.get(i);

      for (let p = 0; p < macadam.get('paths').size; p++) {
        const path =  macadam.get('paths').get(p);

        if (!(state.directions.includes(path.get('direction'))) || !path.get('start_latitude')) continue;

        const date = new Date(path.get('date'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
        + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

        let color;

        if (state.selectedLine === path.get('id')) {
          color = '#00FFFF';
        }
        else if (path.get('direction') === 1) {
          color = '#0000FF';
        }
        else {
          color = '#00FF00';
        }

        const text = (
          <span>
            {time}
            <br/>
            {!this.props.REPTAIL ?
              <span>
                Aloitus: {path.get('road')} / Osa: {path.get('start_part')} / Paalu: {path.get('start_distance')}
                <br/>
                Lopetus: {path.get('road')} / Osa: {path.get('end_part') || '- '} / Paalu: {path.get('end_distance') || '-'}
              </span>
              : null
            }
            <br/>
            Ajorata: {path.get('roadway')}
            <br/>
            Suunta: {path.get('direction')}
            <br/>
            Kaista: {path.get('lane')}
            <br/>
            Rekka: {macadam.get('truck').get('register_number') ? macadam.get('truck').get('register_number') : '-'}
            <br/>
            { macadam.get('paths').size > 1 ?
              <div>
                Arvio käytetystä massasta: {macadam.get('truck_mass') ? 
                  Math.round(macadam.get('truck_mass') / macadam.get('paths').size * 100) / 100
                  : '-'}
              </div>
              : null
            }
            Kuorman koko: {macadam.get('truck_mass') ? Math.round(macadam.get('truck_mass') * 100) / 100 : '-'}
            <br/>
            Pituus: {path.get('length') + ' m'}
          </span>
        );

        const mapPath = props.mapPaths[path.get('id')];

        if (mapPath.length === 1) {
          mapPath.push(mapPath[0]);
        }

        lines.push(
          <Polyline key={path.get('id')}
                    positions={mapPath}
                    weight={10}
                    opacity={0.6}
                    color={color}
                    onClick={this.selectLine.bind(null, path.get('id'))}
                  >
            <Popup autoPan={false}>
              {text}
            </Popup>
          </Polyline>
        );
      }
    }

    this.setState({ lines: lines })
  }

  async getTrucks() {
    let trucks = [];

    await fetch('/vehicles/' + this.props.organizationId).then(data => {
      if (!data.vehicles) return;

      data.vehicles.forEach(truck => {
        if (truck['latitude'] != null) {
          const date = new Date(truck['time']);
          const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
          + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());
    
          trucks.push(
            <Marker key={truck['vehicleId']}
                    position={[truck['latitude'], truck['longitude']]}
                    icon={new L.Icon({
                      iconUrl: 'truck.gif',
                      iconSize: [45, 36],
                      iconAnchor: [23, 36],
                      popupAnchor: [null, -36]
                    })}>
              <Popup autoPan={false}>
                <span>
                  {truck['vehicleId']}
                </span>
                <br/>
                <span>
                  Viimeisin aika: {time}
                </span>
              </Popup>
            </Marker>
          );
        }
      });

      this.setState({ trucks: trucks });
    }).catch(error => {
      console.log(error);
    });
  }

  changeDirection(value, event) {
    let directions = this.state.directions.slice();

    if (event.target.checked) {
      directions.push(value);
    }
    else {
      const index = directions.findIndex(direction => direction === value);
      directions.splice(index, 1);
    }

    this.setState({ directions: directions });
  }

  selectLine(index) {
    this.setState({
      selectedLine: index
    });
  }

  render() {
    return (
      <div>
        <div className='center'>
          {'Suunnat: '}
          <label className='radio-container'>
            1
            <input id='direction1' type='checkbox' name='direction' value='1'
              onChange={this.changeDirection.bind(this, 1)}
              defaultChecked={this.state.directions.includes(1)} />
            <span className="checkmark"></span>
          </label>
          <label className='radio-container'>
            2
            <input id='direction2' type='checkbox' name='direction' value='2'
              onChange={this.changeDirection.bind(this, 2)}
              defaultChecked={this.state.directions.includes(2)} />
            <span className="checkmark"></span>
          </label>
        </div>
        <div style={{
                position: "fixed",
                left: 0,
                height: this.state.height,
                width: '100%'
             }}>
          <link rel='stylesheet' href='https://unpkg.com/leaflet@1.4.0/dist/leaflet.css'
                integrity='sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=='
                crossOrigin='' />
          <Map ref={element => this.map = element} id="map-area" center={this.position} zoom={this.zoom} maxZoom={19} onClick={this.selectLine.bind(null, null)}>
            <TileLayer url={this.state.mapTilesUrl}
                       attribution={this.state.mapTilesAttribution}
                       maxZoom={19} />
            { this.props.site != null && this.props.site.get('coating_plant_laditude') != null ? (
                <Marker position={[this.props.site.get('coating_plant_laditude'), this.props.site.get('coating_plant_longitude')]}
                        icon={new L.Icon({iconUrl: 'coating_plant.gif',
                              iconSize: [33, 50],
                              iconAnchor: [17, 50],
                              popupAnchor: [null, -50]
                              })}>
                  <Popup>
                    <span>{this.props.site.get('coating_plant_laditude')}, {this.props.site.get('coating_plant_longitude')}</span>
                  </Popup>
                </Marker>
              ) : null }
            { this.props.yourLaditude != null ? (
                <Marker position={[this.props.yourLaditude, this.props.yourLongitude]}
                        icon={new L.Icon({iconUrl: 'your_location.gif',
                              iconSize: [18, 43],
                              iconAnchor: [9, 43],
                              popupAnchor: [null, -43]
                              })}>
                  <Popup>
                    <span>{this.props.yourLaditude}, {this.props.yourLongitude}</span>
                  </Popup>
                </Marker>
              ) : null }
            {this.trucks}
            {this.state.lines}
          </Map>
        </div>
        {this.state.showedWarning ? 'Yhtä tai useampaa kuormaa ei voida näyttää sijainti tiedon virheellisyyden vuoksi.' : ''}
        { this.props.loading ?
          <div className='main loader'/> : null
        }
      </div>
    );
  }
};